var directionsDisplay;
var directionsService = new google.maps.DirectionsService();
var bounds;
var paths = [];
var dirs = [];

function getRandomColor() {
  const color = Math.floor(0x1000000 * Math.random()).toString(16);
  return '#' + ('000000' + color).slice(-6);
}

function initialize(map) {
  console.log('initialize');
  directionsDisplay = new google.maps.DirectionsRenderer();
  directionsDisplay.setMap(map);
  bounds = new google.maps.LatLngBounds();
}

function deleteRoute() {
  for (let i = 0; i < paths.length; i++) {
    console.log(paths[i].directions.request.region);
    paths[i].setMap(null);
    // if (paths[i].directions.request.region === sender) {
    // }
  }
  directionsDisplay.setMap(null);
}

function calcRoute(map,request,color) {
  directionsService.route(request,
    function(result, status) {
      if (status === 'OK') {
        directionsDisplay = new google.maps.DirectionsRenderer({
          polylineOptions: {
            strokeColor: color,
            strokeOpacity: 1.0,
            strokeWeight: 5,
          },
          suppressMarkers: true,
          preserveViewport: true // don't zoom to fit the route
        });
        paths.push(directionsDisplay);
        // console.log(paths.length);
        directionsDisplay.setMap(map);
        directionsDisplay.setDirections(result);
        var leg = result.routes[ 0 ].legs[ 0 ];
        const myCenter = new google.maps.LatLng(40.94256444, 16.87774658);
        for (let i = 0; i < leg.steps.length; i++) {
          const Path = new google.maps.Polyline({
            path: leg.steps[i].path,
            geodesic: true,
            strokeColor: "#FF0000",
            strokeOpacity: 1.0,
            strokeWeight: 2,
          });
          if (google.maps.geometry.poly.isLocationOnEdge(myCenter, Path, 10e-1)) {
            console.log('Relocate!');
            // console.log(leg);
          }
        }
        //console.log(leg.steps.length, leg.steps[0].path);

        // combine the bounds of the responses
        // bounds.union(result.routes[0].bounds);
        // zoom and center the map to show all the routes
        // map.fitBounds(bounds);
        // makeMarker( leg.start_location, '../../../assets/markers/green.png', map, 28,24);
        // makeMarker( leg.end_location, '../../../assets/markers/red.png', map, 28, 24);

        for (let i = 0, len = result.routes.length; i < len; i++) {

          dirs[i] = new google.maps.DirectionsRenderer({
            // map: map,
            directions: result,
            routeIndex: i,
            // polylineOptions: { strokeColor: getRandomColor() },
            suppressMarkers: true,
          });
        }
        // console.log(dirs);
      }
      // ====== Decode the error status ======
      else {
        console.log("status=" + status);
      }
    });
}

function makeMarker( position, icon, map, height, width) {
  new google.maps.Marker({
    position: position,
    map: map,
    icon: {url: icon, scaledSize: {height: height, width: width}},
  });
}






function calculateAndDisplayRoute(directionsService, directionsRenderer) {
  // const waypts = [];
  // const checkboxArray = document.getElementById("waypoints");
  // const waypoint = [{location: new google.maps.LatLng(45.658197,-73.636333), stopover: true}];


  // for (let i = 0; i < checkboxArray.length; i++) {
  //   if (checkboxArray.options[i].selected) {
  //     console.log(checkboxArray[i].value);
  //     waypts.push({
  //       location: checkboxArray[i].value,
  //       stopover: true,
  //     });
  //   }
  // }
  directionsService.route(
    {
      origin: document.getElementById("start").value,
      destination: document.getElementById("end").value,
      waypoints: waypoint,
      provideRouteAlternatives: true,
      optimizeWaypoints: true,
      travelMode: google.maps.TravelMode.DRIVING,
    },
    (response, status) => {
      if (status === "OK") {
        directionsRenderer.setDirections(response);
        const route = response.routes[0];
        const summaryPanel = document.getElementById("directions-panel");
        summaryPanel.innerHTML = "";

        // For each route, display summary information.
        for (let i = 0; i < route.legs.length; i++) {
          const routeSegment = i + 1;
          summaryPanel.innerHTML +=
            "<b>Route Segment: " + routeSegment + "</b><br>";
          summaryPanel.innerHTML += route.legs[i].start_address + " to ";
          summaryPanel.innerHTML += route.legs[i].end_address + "<br>";
          summaryPanel.innerHTML += route.legs[i].distance.text + "<br><br>";
        }
      } else {
        window.alert("Directions request failed due to " + status);
      }
    }
  );
}
