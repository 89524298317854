function initMap(map, date, index, period, check) {
  let url;
  const imageBounds = new google.maps.LatLngBounds(new google.maps.LatLng(39.792,14.9317),new google.maps.LatLng(41.9500,18.518));
  url = "https://ophidialab.cmcc.it/ophidia/ofidia/" + date + "/maps/" + index + "/" + period + ".png";
  if(check){
    overlay = newOverlay(url, imageBounds);
  }
  else {
    overlay.set("url", url);
    overlay.set("bounds",imageBounds);
  }
  overlay.setMap(map);
  return overlay;
}
function newOverlay (url, imageBounds) {
  overlay = new google.maps.GroundOverlay(
    url,
    imageBounds,
  );
  return overlay;
}
